import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import {
  AdditionalEducationalDetailsComponent
} from '../../dialog/additional-educational-details/additional-educational-details.component';
import { AdditionalWorkDetailsComponent } from '../../dialog/additional-work-details/additional-work-details.component';
import { ApplicationDialogComponent } from '../../component/application-dialog/application-dialog.component';
import { ApplicationLoaderComponent } from '../../component/application-loader/application-loader.component';
import { ApplicationNotifierComponent } from '../../component/application-notifier/application-notifier.component';
import { BackSectionComponent } from '../../dialog/back-section/back-section.component';
import { ChangePasswordDialogComponent } from '../../component/change-password-dialog/change-password-dialog.component';
import { CkEditorComponent } from '../../component/ck-editor/ck-editor.component';
import { CkEditorModalComponent } from '../../component/ck-editor/ck-editor-modal/ck-editor-modal.component';
import { CreateQuestionnaireComponent } from '../../component/create-questionnaire/create-questionnaire.component';
import { CountDownTimerComponent } from '../../component/count-down-timer/count-down-timer.component';
import { DragDropUploadFileDirective } from '../../directive/drag-drop-upload-file.directive';
import { ErrorMessagePipe } from '../../pipe/error-message.pipe';
import { GeneratePdfComponent } from '../../component/generate-pdf/generate-pdf.component';
import { NetworkInterceptor } from '../../../interceptor/network.interceptor';
import { PaginatorComponent } from '../../component/paginator/paginator.component';
import { ProgressbarComponent } from '../../component/progressbar/progressbar.component';
import { QuestionDeleteAlertComponent } from '../../component/create-questionnaire/question-delete-alert/question-delete-alert.component';
import { ThankYouComponent } from '../../component/thank-you/thank-you.component';
import { ViewAsLearnerComponent } from '../../component/view-as-learner/view-as-learner.component';
import { YearMonthPipe } from '../../pipe/year-month.pipe';
import { AthenaFormComponent } from '../../component/athena-form/athena-form.component';
import { AthenaFormDialogComponent } from '../../component/athena-form-dialog/athena-form-dialog.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FaqComponent } from '../../component/faq/faq.component';
import { HtmlTrimPipe } from '../../pipe/html-trim.pipe';
import { TimeFormatPipe } from '../../pipe/time-format.pipe';
import { AddRemarksComponent } from '../../component/add-remarks/add-remarks.component';
import { PaginatorNewComponent } from '../../component/paginator-new/paginator-new.component';
import { MultiSelectPopupComponent } from '../../component/multi-select-popup/multi-select-popup.component';

@NgModule({
  declarations: [
    AdditionalEducationalDetailsComponent,
    AdditionalWorkDetailsComponent,
    AddRemarksComponent,
    ApplicationDialogComponent,
    ApplicationLoaderComponent,
    ApplicationNotifierComponent,
    AthenaFormComponent,
    AthenaFormDialogComponent,
    BackSectionComponent,
    ChangePasswordDialogComponent,
    CkEditorComponent,
    CkEditorModalComponent,
    CountDownTimerComponent,
    CreateQuestionnaireComponent,
    DragDropUploadFileDirective,
    ErrorMessagePipe,
    FaqComponent,
    GeneratePdfComponent,
    HtmlTrimPipe,
    MultiSelectPopupComponent,
    PaginatorComponent,
    PaginatorNewComponent,
    ProgressbarComponent,
    QuestionDeleteAlertComponent,
    ThankYouComponent,
    TimeFormatPipe,
    ViewAsLearnerComponent,
    YearMonthPipe
  ],
  entryComponents: [
    AdditionalEducationalDetailsComponent,
    AdditionalWorkDetailsComponent,
    AddRemarksComponent,
    ApplicationDialogComponent,
    ApplicationNotifierComponent,
    AthenaFormDialogComponent,
    BackSectionComponent,
    ChangePasswordDialogComponent,
    CkEditorModalComponent,
    MultiSelectPopupComponent,
    QuestionDeleteAlertComponent
  ],
  exports: [
    AccordionModule,
    ApplicationLoaderComponent,
    AddRemarksComponent,
    BackSectionComponent,
    BsDropdownModule,
    ChangePasswordDialogComponent,
    CkEditorComponent,
    CkEditorModalComponent,
    CKEditorModule,
    CommonModule,
    CountDownTimerComponent,
    CreateQuestionnaireComponent,
    CustomMaterialModule,
    DragDropUploadFileDirective,
    ErrorMessagePipe,
    FormsModule,
    FaqComponent,
    GeneratePdfComponent,
    HtmlTrimPipe,
    NgxIntlTelInputModule,
    NgxPermissionsModule,
    PaginatorComponent,
    PaginatorNewComponent,
    ProgressbarComponent,
    ReactiveFormsModule,
    RouterModule,
    TimeFormatPipe,
    ViewAsLearnerComponent,
    YearMonthPipe
  ],
  imports: [
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    CKEditorModule,
    CommonModule,
    CustomMaterialModule,
    FormsModule,
    NgxIntlTelInputModule,
    NgxPermissionsModule.forChild(),
    ReactiveFormsModule,
    RouterModule
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true
    },
    HtmlTrimPipe
  ]
})
export class SharedModule { }
